/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {includes} from 'lodash'
import {FC} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_biha/layout/MasterLayout'
import {App} from '../App'
import {AuthPage, Logout, Module, useAuth} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Error403} from '../modules/errors/components/Error403'
import {PrivateRoutes} from './PrivateRoutes'
import {MEMBER_MANAGEMENT_SUB_PATHNAME} from '../../_biha/helpers'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            includes(currentUser?.modules, Module.MANAGE_MEMBERSHIP) ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/membership-social' />} />
                <Route index element={<Navigate to='/invoice' />} />
                <Route index element={<Navigate to='/setting' />} />
                <Route
                  index
                  element={<Navigate to={`/${MEMBER_MANAGEMENT_SUB_PATHNAME.INDEX}`} />}
                />
              </>
            ) : (
              <Route element={<MasterLayout />}>
                <Route path='*' element={<Error403 />} />
              </Route>
            )
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
          {/* {currentUser ? (
            includes(currentUser?.modules, Module.UPGRADE_ACCOUNT) ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/membership-social' />} />
              </>
            ) : (
              <Route element={<MasterLayout />}>
                <Route path='*' element={<Error403 />} />
              </Route>
            )
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )} */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
