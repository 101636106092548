import {FC, Suspense, lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_biha/assets/ts/_utils'
import {MEMBER_MANAGEMENT_SUB_PATHNAME, WithChildren} from '../../_biha/helpers'
import {MasterLayout} from '../../_biha/layout/MasterLayout'

const PrivateRoutes = () => {
  const MemberManagementPage = lazy(() =>
    import('../modules/apps/member-management/MemberManagementPage').then((module) => ({
      default: module.MemberManagementPage,
    }))
  )

  const MemberShipPage = lazy(() =>
    import('../modules/apps/membership-social-management/MembershipSocialPage').then((module) => ({
      default: module.MembershipSocialPage,
    }))
  )

  const InvoicePage = lazy(() =>
    import('../modules/apps/invoice-management/InvoicePage').then((module) => ({
      default: module.InvoicePage,
    }))
  )

  const SettingPage = lazy(() =>
    import('../modules/apps/setting-management/SettingPage').then((module) => ({
      default: module.SettingPage,
    }))
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/membership-social' />} />
        {/* Lazy Modules */}
        <Route
          path={`${MEMBER_MANAGEMENT_SUB_PATHNAME.INDEX}/*`}
          element={
            <SuspensedView>
              <MemberManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='membership-social/*'
          element={
            <SuspensedView>
              <MemberShipPage />
            </SuspensedView>
          }
        />
        <Route
          path='invoice/*'
          element={
            <SuspensedView>
              <InvoicePage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/*'
          element={
            <SuspensedView>
              <SettingPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
