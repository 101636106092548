import {
  filter,
  find,
  groupBy,
  includes,
  isEmpty,
  isEqual,
  isNil,
  isObject,
  join,
  map,
  startsWith,
  toNumber,
  toString,
  transform,
  uniq,
} from 'lodash'
import {HistorySourceObject, OpsType} from '../../app/modules/apps/shared/edit-history/core/_model'
import {IntlShape} from 'react-intl'
import moment from 'moment'

const ParseEditHistoryData = (object: any) => {
  if (!isNil(object)) {
    const flattenObject = (obj: any, parent?: string, result = {}) => {
      return transform(
        obj,
        (result, value, key) => {
          let newKey = parent ? `${parent}/${key as any}` : key
          if (
            isObject(value) &&
            !isEqual(key, 'originalUrl') &&
            !isEqual((value as any).__typename, 'File')
          ) {
            flattenObject(value, newKey as any, result)
          } else {
            ;(result as any)[newKey] = value
          }
        },
        result
      )
    }
    const valueArrayObject = isNil(object.value)
      ? undefined
      : map(flattenObject(object.value, object.path), (value, key) => ({
          title: key,
          content: value,
        }))
    const originalValueArrayObject = isNil(object.originalValue)
      ? undefined
      : map(flattenObject(object.originalValue, object.path), (value, key) => ({
          title: key,
          content: value,
        }))
    const allTitles = uniq([
      ...map(valueArrayObject, (obj) => obj.title),
      ...map(originalValueArrayObject, (obj) => obj.title),
    ])
    const response = map(allTitles, (item) => {
      const valueInValueArrayObject = find(valueArrayObject, (valueArrayObjectItem) =>
        isEqual(valueArrayObjectItem?.title, item)
      )?.content
      const originalValueInOriginalValueArrayObject = find(
        originalValueArrayObject,
        (valueArrayObjectItem) => isEqual(valueArrayObjectItem?.title, item)
      )?.content
      return {
        op: object.op,
        path: item,
        value: valueInValueArrayObject,
        originalValue: originalValueInOriginalValueArrayObject,
      }
    })
    return response
  } else {
    return undefined
  }
}
const renderValueWithType = (
  newOps?: (
    | {
        op: any
        path: string
        value: undefined
        originalValue: undefined
      }
    | OpsType
    | undefined
  )[],
  source?: HistorySourceObject,
  intl?: IntlShape,
  value?: JSON
) => {
  const {typeValue, translationNode, path, dateTimeFormat} = source ?? {}
  switch (typeValue) {
    case 'string':
      return toString(value)
    case 'stringWithTranslate':
      return (
        intl &&
        intl.formatMessage({
          id: `${translationNode}.${toString(value)}`,
        })
      )
    case 'number':
      return toNumber(value)?.toLocaleString('en-US')
    case 'array':
      return join(
        map(value, (item) => item),
        ','
      )
    case 'arrayWithTranslate':
      return (
        intl &&
        join(
          map(value, (itemValue) =>
            intl.formatMessage({
              id: `${translationNode}.${toString(itemValue)}`,
            })
          ),
          ', '
        )
      )
    case 'datetime':
      return !isNil(value) ? moment(toString(value)).format(toString(dateTimeFormat)) : undefined
    case 'media':
      const attachments = filter(
        newOps,
        (op: any) =>
          startsWith(toString(op.path), toString(path)) && includes(op.path, toString(path))
      )
      const groupedAttachments = groupBy(attachments, 'op')
      const addedAttachments = filter(
        groupedAttachments['add'],
        (file) => !isNil(file?.value) || !isNil(file?.originalValue)
      )
      const removedAttachments = filter(
        groupedAttachments['remove'],
        (file) => !isNil(file?.value) || !isNil(file?.originalValue)
      )
      const replacedAttachments = filter(
        groupedAttachments['replace'],
        (file) => !isNil(file?.value) || !isNil(file?.originalValue)
      )
      return {
        addedAttachments: !isEmpty(addedAttachments) ? addedAttachments : undefined,
        removedAttachments: !isEmpty(removedAttachments) ? removedAttachments : undefined,
        replacedAttachments: !isEmpty(replacedAttachments) ? replacedAttachments : undefined,
      }
    case 'object':
      const objects = filter(
        newOps,
        (op: any) =>
          startsWith(toString(op.path), toString(path)) && includes(op.path, toString(path))
      )
      const groupedObjects = groupBy(objects, 'op')
      const addedObjects = filter(
        groupedObjects['add'],
        (item) => !isNil(item?.value) || !isNil(item?.originalValue)
      )
      const removedObjects = filter(
        groupedObjects['remove'],
        (item) => !isNil(item?.value) || !isNil(item?.originalValue)
      )
      const replacedObjects = filter(
        groupedObjects['replace'],
        (item) => !isNil(item?.value) || !isNil(item?.originalValue)
      )
      return {
        addedObjects: !isEmpty(addedObjects) ? addedObjects : undefined,
        removedObjects: !isEmpty(removedObjects) ? removedObjects : undefined,
        replacedObjects: !isEmpty(replacedObjects) ? replacedObjects : undefined,
      }
    default:
      break
  }
}
export {ParseEditHistoryData, renderValueWithType}
