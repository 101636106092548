/* eslint-disable react/jsx-no-target-blank */
import {Fragment} from 'react'
import {INVOICE_PATHNAME, MEMBER_MANAGEMENT_SUB_PATHNAME} from '../../../../helpers'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => (
  <Fragment>
    <SidebarMenuItem
      to={`/${MEMBER_MANAGEMENT_SUB_PATHNAME.INDEX}`}
      title='Quản lý hội viên'
      icon='/media/biha/icons/svg/medal-star.svg'
    />
    <SidebarMenuItem
      to='/membership-social'
      title='Quản lý người dùng'
      icon='/media/biha/icons/svg/fluent_text-bullet-list-square-toolbox-20-filled.svg'
    />
    <SidebarMenuItem
      to={`/${INVOICE_PATHNAME}`}
      title='Đối soát hóa đơn'
      icon='/media/biha/icons/svg/e-commerce.svg'
    />
    <SidebarMenuItem to='/setting' title='Cấu hình' icon='/media/biha/icons/svg/it-network.svg' />
  </Fragment>
)

export {SidebarMenuMain}
