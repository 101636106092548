const QUERIES = {
  CONFIG: 'config',
  CUSTOMERS_LIST: 'customers-list',
  DEMAND_LIST: 'demand-list',
  PROVINCE_LIST: 'province-list',
  DISTRICT_LIST: 'district-list',
  WARD_LIST: 'ward-list',
  STREET_LIST: 'street-list',
  COMMENT: 'comment',
  UPGRADE_ACCOUNT_LIST: 'upgrade-account-list',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  INVOICE_LIST: 'invoice-list',
  MEMBERSHIP_SOCIAL: 'membership-social',
  USER_CONFIG: 'user-config',
  USER_LIST: 'user-list',
  SETTING_ROLE: 'setting-role',
}
const INVOICE_QUERIES = {
  CONFIG: 'config',
  PROVINCE_LIST: 'province-list',
  COMMENT: 'comment',
  INVOICE_LIST: 'invoice-list',
  BANK_LIST: 'bank-list',
  USER_LIST: 'user-list',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
}

const INVOICE_PATHNAME = 'invoice'
const INVOICE_SUB_PATHNAME = {
  LIST: 'list',
  OVERVIEW: 'overview',
  RESUBMIT: 'resubmit',
  SEND_APPROVE: 'send-approve',
  NEW: 'new',
  EDIT: 'edit',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  NOTE: 'note',
  SLUG_ID: ':id',
}

const CONFIG_QUERIES = {
  CONFIG: 'config',
}
const BANK_QUERIES = {
  BANK_LIST: 'bank-list',
}
const USER_CONFIG_QUERIES = {
  USER_CONFIG: 'user-config',
}
const MEMBER_MANAGEMENT_QUERIES = {
  MEMBER_LIST: 'member-list',
  DETAIL: 'detail-member',
  SOCIAL_DEVELOPER_LIST: 'social-developer-list',
  CREATED_BY_LIST: 'created-list',
  APPROVED_BY_LIST: 'approved-list',
  PROCESSED_BY_LIST: 'processed-list',
  REJECTED_BY_LIST: 'rejected-list',
  MEMBER_ROLE_LIST: 'member-role-list',
}
const MEMBER_MANAGEMENT_SUB_PATHNAME = {
  INDEX: 'member-management',
  LIST: 'list',
  OVERVIEW: 'overview',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  NOTE: 'note',
  SLUG_ID: ':id',
  NEW: 'new',
  PROCESS_AND_RESUBMIT: 'process_and_resubmit',
  SEND_APPROVE: 'send-approve',
  EDIT: 'edit',
}
const MEMBERSHIP_MANAGEMENT_SUB_PATHNAME = {
  INDEX: 'membership-social',
  LIST: 'list',
  OVERVIEW: 'overview',
  UPGRADE: 'upgrade',
  EDIT: 'edit',
  SLUG_ID: ':id',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  NOTE: 'note',
}
const SETTING_MANAGEMENT_SUB_PATHNAME = {
  INDEX: 'setting',
  LIST: 'list',
}

export {
  BANK_QUERIES,
  CONFIG_QUERIES,
  INVOICE_PATHNAME,
  INVOICE_QUERIES,
  INVOICE_SUB_PATHNAME,
  MEMBERSHIP_MANAGEMENT_SUB_PATHNAME,
  MEMBER_MANAGEMENT_QUERIES,
  MEMBER_MANAGEMENT_SUB_PATHNAME,
  QUERIES,
  USER_CONFIG_QUERIES,
  SETTING_MANAGEMENT_SUB_PATHNAME,
}
